<template>

  <div class="block block--about">
    <div class="block block--welcome">
      <div class="card block__card">
        <div class="block__content card-body">
          <h3 class="block__title">{{ $j('footerTitle') }}</h3>
          <p>{{ $j('eGovDescription') }}</p>
        </div>
      </div>
    </div>
    <div class="block__content"> 
      <div class="block__row row gy-4"> 
        <div class="block__col col-12 col-lg-6">
          <div class="block__card card">
            <div class="block__body card-body">
              <b class="h3 block__subtitle">{{ $j("tasks") }}</b>
              <div class="block__text">
                <ul>
                  <li>{{ $j("tasks1") }}</li>
                  <li>{{ $j("tasks2") }}</li>
                  <li>{{ $j("tasks3") }}</li>
                  <li>{{ $j("tasks4") }}</li>
                  <li>{{ $j("tasks5") }}</li>
                  <li>{{ $j("tasks6") }}</li>
                  <li>{{ $j("tasks7") }}</li>
                  <li>{{ $j("tasks8") }}</li>
                </ul>
              </div>              
            </div>
          </div>
        </div>
        <div class="block__col col-12 col-lg-6">
          <div class="block__card card">
            <div class="block__header card-body">
              <h3 class="block__title mb-4">{{ $j("funct") }}</h3>
              <div class="block__text">
                <ul>
                  <li>{{ $j("funct1") }}</li>
                  <li>{{ $j("funct2") }}</li>
                  <li>{{ $j("funct3") }}</li>
                  <li>{{ $j("funct4") }}</li>
                  <li>{{ $j("funct5") }}</li>
                  <li>{{ $j("funct6") }}</li>
                  <li>{{ $j("funct7") }}</li>
                </ul>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
};
</script>